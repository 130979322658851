import Barba from 'barba.js'
import bind from 'es6-class-bind-all'
import select from 'dom-select'
import config from '../config'
import { detect } from 'detect-browser'
import configQ from '../util/func/configQ'
import saveCol from '../util/func/saveCol'
import {
	watchViewport,
	unwatchViewport,
	getViewportState
} from 'tornis'


class Default {
	constructor(opt = {}) {
		this.barba = Barba.BaseView.extend({
			namespace: opt,
			onEnter: () => this.onEnter(),
			onEnterCompleted: () => this.onEnterCompleted(),
			onLeave: () => this.onLeave(),
			onLeaveCompleted: () => this.onLeaveCompleted(),
			onResize: (w, h) => this.onResize(w, h)
		})
		bind(this)
	}

	onEnter() {

		configQ(window.innerWidth)
		this.beforeMount && this.beforeMount()
		this.browser = detect()

	}

	quidChargeImage() {

		console.log('quid')
		const images = [...document.querySelectorAll('img')]
		images.forEach((image) => {

			!image.classList.contains('loaded') && this.chargeImage(image)

		})

	}

	chargeImage(image) {

        if(image) {
			image.src = image.dataset.src
        	image.classList.add('loaded')
		}

    }

	startTornis() {

		const updateValues = ({
			size,
			scroll,
			mouse,
			orientation
		}) => {
			if (scroll.changed) {
				this.onSmoothScroll && this.onSmoothScroll(scroll.top)
			}
		}
		watchViewport(updateValues)
	}

	onEnterCompleted() {

		this.mounted && this.mounted()
		this.quidChargeImage()
		config.isTwo && this.setHeightMobile()
		saveCol(window.innerWidth)
        this.getWithExpiry('preloader')
		this.startTornis()

    }

    getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            console.log('périmé')
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

	setHeightMobile() {

		let vh = window.innerHeight * 0.01
		select('html').style.setProperty('--vh', `${vh}px`)

	}


	onLeave() {

		this.beforeDestroy && this.beforeDestroy()

	}

	onLeaveCompleted() {

		this.destroyed && this.destroyed()

	}

	onResize(w, h) {

		this.resize && this.resize(w, h)
		config.isTwo && this.setHeightMobile()
		configQ(w)
		saveCol(w)

	}

	onSmoothScroll(value) {

		this.onScroll && this.onScroll(value)

	}

}

export default Default