import Default from './default'
import es6ClassBindAll from 'es6-class-bind-all'
import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import videojs from 'video.js'
import config from '../config'


class Home extends Default {
	constructor() {
		super('home')
	}

    mounted() {

        this.cache()
        this.init()
        this.monitor(this)

    }

    cache() {

        this.top = select('.top')
        this.bottom = select('.bottom')
        this.home = select('.home')
        this.scrollEvent = true
        this.oldScroll = select('body').getBoundingClientRect().top

    }


    init() {
        
        this.quidImage()
        config.isOne && this.initTitleMobile()
        this.quidIndex()
        this.addEvents('on', true)
        this.initVideo()

    }

    quidImage() {

        const images = [...document.querySelectorAll('img')]
        
        images.forEach((image) => {
            if (!image.classList.contains('loaded')) {

                image.src = image.dataset.src
                image.classList.add('loaded')

            }
        })

    }

    addEvents(type, accept) {

        this.scrollaccept = accept

        event[type](window, 'scroll', () => {
            this.monitor(this)
        }, false)

        event[type](select('.index_button'), 'click', () => {
            this.toggleIndex(this)})

    }

    quidIndex() {

        const index = localStorage.getItem('index')
		index == 'true' && this.openIndex()
        localStorage.setItem('index', false)

    }

    initVideo() {

        const videos = [...document.querySelectorAll('video')]
        videos.forEach((video) => {

            var options = {};

            if (!config.isOne) {

                const player = videojs(video, options, function onPlayerReady() {
                    videojs.log('Your player is ready!');
                  
                    this.on('mouseover',() => {
    
                        this.play()
    
                    })
    
                    this.on('mouseleave',() => {
    
                        this.pause()
    
                    })
                  
                    // How about an event listener?
                    this.on('ended', () => {
                      videojs.log('Awww...over so soon?!');
                    })
                  })

            } else {

                const player = videojs(video, options, function onPlayerReady() {
                    videojs.log('Your player is ready mob !');
                  
                    this.on('touchstart', function() {
                        if (player.paused()) {
                          player.play();
                        } else {
                          player.pause();
                        }
                      });
                  
                    // How about an event listener?
                    this.on('ended', () => {
                      videojs.log('Awww...over so soon?!');
                    })
                  })

            }

        })

    }

    initTitleMobile() {

        const titles = [...document.querySelectorAll('.first_item')]
        titles.forEach((title) => {
            let text = title.querySelector('.title_text').textContent
            const length = text.length
            if ( length > 30 ) { title.querySelector('.title_text').textContent = `${text.substring(0,30)}...` }
        })

    }

    monitor(first) {

        if(this.scrollaccept == true) {

            const homes = [...document.querySelectorAll('.wrapper_home')]
            const seconds = [...document.querySelectorAll('.wrapper_second')]
            const bands = homes.concat(seconds)
            const height = window.innerHeight
            const scroll = select('body').getBoundingClientRect().top

            if (!select('.home').classList.contains('index_mode')) {

                bands.forEach((band) => {

                    const topPart    = band.getBoundingClientRect().top,
                          bottomPart = band.getBoundingClientRect().bottom

                    // je retire le premier projet
                    if (band !== bands[0]) {

                        const top = band.getBoundingClientRect().top

                        if(top < height && top > 0) {

                            const grow = ((top - height) * -1) + (height / 20)
                            const bandImage = band.querySelector('.images_bands')
                            !config.isOne 
                            ? bandImage.style.height = `${(grow / 3.6) + 70}px`
                            : bandImage.style.height = `${(grow / 6) + 70}px`
                            

                            if(!config.isOne) {

                                let widthImages = 0
                                const images = [...band.querySelectorAll('img')]
                                const widthBand = window.innerWidth
                                const wrapper = band.querySelector('.wrapper_image')
                                images.forEach((image) => {
        
                                    const widthImage = image.offsetWidth
                                    widthImages = widthImages + widthImage
        
                                })
        
                                if (widthImages < widthBand) {
                                    if (wrapper.classList.contains('center')) {
                                        wrapper.classList.remove('center')
                                    }
        
                                } else {
                                    if (!wrapper.classList.contains('center')) {
                                        wrapper.classList.add('center')
                                    }
                                }

                                if (images.length == 0) { wrapper.classList.add('center')}

                            }
                        }
                    }

                    // infinit scroll
                    if (topPart < 0) {
    
                        if (!band.classList.contains('gone')) {
    
                            if (band.classList.contains('wrapper_home') || band.classList.contains('wrapper_second')) {

                                band.classList.add('gone')
                                const contentBand = band.innerHTML
                                const newBand = document.createElement("div")
                                newBand.classList.add('wrapper_serie')
                                newBand.classList.add('wrapper_second')
                                newBand.insertAdjacentHTML('beforeend', contentBand)
                                select('.home').appendChild(newBand)

                            }
    
                        } 
                    }

                })
            }

            this.oldScroll = scroll
        }
    }

    toggleIndex(first) {

        select('.home').classList.contains('index_mode')
        ? first.closeIndex(first)
        : first.openIndex()
        
    }

    openIndex() {
        
        this.scrollEvent = false
        select('.home').classList.add('index_mode')
        const height = window.innerHeight
        const bands = [...document.querySelectorAll('.wrapper_serie')]
        const width = window.innerWidth

        bands.forEach((item) => {

            const band = item.querySelector('.images_bands')
            const wrapper = band.querySelector('.wrapper_image')
            
            if(!item.classList.contains('wrapper_second')) {

                band.style.transition = 'height 0.3s ease'

                !config.isOne
                ? band.style.height =`${height / 15}px`
                : band.style.height = '60px'

                wrapper.classList.remove('center')

            } else {

                band.style.transition = 'height 0.3s ease'
                band.style.height = '0px'

            }

            const images = [...item.querySelectorAll('img')]
            let keymax
            config.isOne
            ? keymax = 5
            : keymax = 9
            images.forEach((image, key) => {

                key == 0 && image.classList.add('first')
                key > keymax  && images[key - 1].classList.add('last')
                key > keymax && image.classList.add('hide')

            })
        })
    }

    closeIndex(first) {

        this.scrollEvent = true
        select('.home').classList.remove('index_mode')
        const bands = [...document.querySelectorAll('.images_bands')]
        bands[0].style.height = `${window.innerHeight - 36}px`
        first.monitor(first)
        bands.forEach((band) => {band.style.transition = 'height 0s ease'})

        document.querySelectorAll('hide') && document.querySelectorAll('hide').classList.remove('hide')
        document.querySelectorAll('first') && document.querySelectorAll('first').classList.remove('first')
        document.querySelectorAll('last') && document.querySelectorAll('first').classList.remove('last ')
    }

    initPagination() {

        const projets = [...document.querySelectorAll('.wrapper_serie')]
        projets.forEach((projet) => {

            const images = [...projet.querySelectorAll('img')]
            const videos = [...projet.querySelectorAll('div[aria-label="Video Player"]')]
            const content = images.concat(videos)
            projet.querySelector('.pagination').textContent =`(${content.length})`

        })

    }

    beforeDestroy() {

        console.log('before')
        this.addEvents('off', false)

    }
}

export default Home