import Contact from './contact'
import Home from './home'
import Manifesto from './manifesto'
import Press from './press'
import Projet from './projet'



const sections = [

	new Home,
	new Projet,
	new Press,
	new Contact,
	new Manifesto

]

export default sections
