import Default from './default'
import select from 'dom-select'
import event from 'dom-event'
import config from '../config'


class Manifesto extends Default {
	constructor() {
		super('manifesto')
	}

    mounted() {

        this.init()

    }

    init() {
        
        this.addEvents('on')

        this.initText()

    }

    addEvents(type) {

        event[type](select('.index_button'), 'click', this.goToHomeList)

    }

    initText() {

        const paras = [...document.querySelectorAll('p')]
        paras.forEach((para, key) => {

            const quidPair = key%2 == 0
            if(!quidPair) { 
                !config.isOne
                ? para.style.paddingLeft = '70px' 
                : para.style.paddingLeft = '30px' 
            }

        })

    }

    goToHomeList() {

        localStorage.setItem('index', true )
        window.location.href = "https://pandoragraessl.com/"

    }

    beforeDestroy() {

        this.addEvents('off')

    }

    beforeDestroy() {

        this.addEvents('off')

    }
}

export default Manifesto