import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import config from '../config'
import bind from 'es6-class-bind-all'
import ImagePreloader from "image-preloader"
import PromiseDelay from "promise-ext-delay"
import remove from "dom-remove"

class Preloader {

	constructor() {
		bind(this)
		this.cache()
		this.init()

	}

	cache() {

	}

	init() {

		const itemStr = localStorage.getItem('preloader')
        const item = JSON.parse(itemStr)
        const images = [...document.querySelectorAll('img')]


        if (item && !select('.projet')) {

            images.forEach((image) => {

                this.chargeImage(image)

            })
            this.end()

        } else if ( !select('.home')) {

            images.forEach((image) => {

                this.chargeImage(image)

            })
            this.end()

        } else {

            this.initStorage()
            this.preloadCount() 

        }   

	}

    preloadCount() {

        PromiseDelay()

        const setLoader = setTimeout(() => {

            const tl = gsap.timeline()

            .to(select('.logo_intro'), {
                duration: 0.7,
                opacity: 1,
                ease: 'Power1.out'
            } )
            .to(select('.counter'), {
                duration: 0.7,
                opacity: 1,
                ease: 'Power1.out'
            }, "-=0.4")
        
        }, 500)


        const preloader = new ImagePreloader,
              progress = select('.number')

        this.loaded = 0

        const images = [...document.querySelectorAll('img')]
        var total = images.length - 1
        var i = 0
        var it = []
        var count = 0

        images.forEach((image) => {

            this.chargeImage(image)

        })

        preloader.onProgress = () => {

            this.loaded = this.loaded + 1
            this.newProgress = parseInt(this.loaded * 100 / total)


            for (let index = this.progress; index < this.newProgress; index++) {

                i++
                it.push(i)

            }

            setInterval(() => {

                if (it.includes(parseInt(count + 1))) {
                    progress.textContent = count + 1
                    count++
                }

            }, 15)

            this.progress = this.newProgress

        }

        preloader.preload(images).delay(100).then((result) => {

            var inter = setInterval(() => {

                if (progress.textContent == '100') {

                    let topLogo = 0
                    !config.isTwo 
                    ? topLogo = 2
                    : topLogo = 50

                    console.log(topLogo)

                    clearInterval(inter)
                    clearTimeout(setLoader)
                    const height = window.innerHeight

                    const tl2 = gsap.timeline()

                    .to(select('.counter'), {
                        duration: 0.4,
                        opacity: 0,
                        ease: 'Power1.out'
                    })

                    .to(select('.preloader'), {
                        duration: 0.8,
                        height: 0,
                        ease: 'Power1.out'
                    })

                    .to(select('.logo_intro'), {
                        duration: 0.8,
                        top: topLogo,
                        ease: 'Power1.out'
                    }, '-=0.8')

                    .call(() => {

                        select('.body').classList.remove('preload')

                    })

                    .to(select('.preloader'), {
                        duration: 0,
                        opacity: 0,
                        onComplete: (() => {
                            remove(select('.preloader'))
                        })
                    })
                }
            }, 500)
        })
    }

    chargeImage(image) {

        image.srcset = image.dataset.src
        image.classList.add('loaded')

    }

    initStorage() {

        const now = new Date()
        const item = {
            value: true,
            expiry: now.getTime() + 100000,
        }

        localStorage.setItem('preloader', JSON.stringify(item))

    }

	end() {

        select('.preloader').remove('preloader')
        select('.body').classList.remove('preload')

    }
}

export default Preloader