import Default from './default'
import es6ClassBindAll from 'es6-class-bind-all'
import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import videojs from 'video.js'
import config from '../config'


class Contact extends Default {
	constructor() {
		super('contact')
	}

    mounted() {

        this.cache()
        this.init()

    }

    cache() {

    }


    init() {
        
        this.addEvents('on')
        this.initText()

    }

    addEvents(type) {

        event[type](select('.index_button'), 'click', this.goToHomeList)

    }

    goToHomeList() {

        localStorage.setItem('index', true )
        window.location.href = "https://pandoragraessl.com/"

    }

    initText() {

        const paras = [...document.querySelectorAll('p')]
        paras.forEach((para, key) => {

            const quidPair = key%2 == 0
            if(!quidPair) { 
                !config.isOne
                ? para.style.paddingLeft = '70px' 
                : para.style.paddingLeft = '30px' 
            }

        })

    }

    onResize() {

        // this.initcarousel()

    }
}

export default Contact